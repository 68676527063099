<template>
    <div>
        <a-tabs v-model="zone" default-active-key="1" size="large">
            <a-tab-pane v-for="zone in zoneList" :key="zone.index" :tab="zone.name"></a-tab-pane>
        </a-tabs>

        <a-card class="para-card" title="环境" bordered size="small">

            <control-para-item v-for="item in irriEnvParas"
                          :option="item"
                          :iotId="iotId"
                          :valueMap="valueMap"
                          :value="getValue(item)"
            ></control-para-item>

        </a-card>


        <a-card class="para-card" title="营养液" bordered size="small">

            <control-para-item v-for="item in irriNutriParas"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>


        <a-card class="para-card" title="土壤" bordered size="small">

            <control-para-item v-for="item in irriSoilParas"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

        </a-card>

        <a-card class="para-card" :tab-list="tabList" bordered size="small" :active-tab-key="tabSelection"
                @tabChange="key => tabSelection=key"
        >
            <control-para-item v-for="item in irriSolenoidParas"
                               :option="item"
                               :iotId="iotId"
                               :valueMap="valueMap"
                               :value="getValue(item)"
            ></control-para-item>

            <div style="clear:both "></div>

            <div class="timing-container">

            <irri-timing-item
                    v-for="(dataIndex,index) in irriTimingIndexArr"
                    :iotId="iotId"
                    :index="index+1"
                    :dataIndex="dataIndex"
                    :value="valueMap[dataIndex+''] || 0"
                    @onValueChange="timingChange"
                ></irri-timing-item>

            </div>

        </a-card>



    </div>
</template>

<script>
    import ParasUtil from "./ParasUtil"
    import ControlParaItem from "./ControlParaItem";
    import IrriTimingItem from "./IrriTimingItem";
    export default {
        components:{ControlParaItem,IrriTimingItem},
        props:["iotId","valueMap","paraName"],
        data(){
            return {
                zone:1,
                tabSelection:0,
                tabList: [
                    {
                        key: 0,
                        tab: 'K1电磁阀',
                    },
                    {
                        key: 1,
                        tab: 'K2电磁阀',
                    },
                    {
                        key: 2,
                        tab: 'K3电磁阀',
                    },
                ],
            }
        },
        computed: {
            zoneList(){
                let list = []
                for(let i=1;i<=5;i++){
                    let dataIndex = i*256 + 0xAC
                    let type = this.valueMap[dataIndex+""]
                    let number = ["⓪","①","②","③","④","⑤"][i]
                    let name = this.paraName[dataIndex+""]
                    let typeName = (["无设备","喷灌","滴灌","喷滴灌",][type||0])
                    name = `${name}(${typeName})`
                    list.push({
                        index:i,
                        dataIndex,
                        type,
                        name:number+" "+name
                    })
                }
                return list
            },
            irriEnvParas(){
                return this.parasWithZone(ParasUtil.irriEnvParas(),1)
            },
            irriNutriParas(){
                return this.parasWithZone(ParasUtil.irriNutriParas())
            },
            irriSoilParas(){
                return this.parasWithZone(ParasUtil.irriSoilParas())
            },
            irriSolenoidParas(){
                return this.parasWithZone(ParasUtil.irriSolenoidParas(this.tabSelection))
            },
            irriTimingIndexArr(){
                let arr = []
                arr.push([0x73,0x74,0x75,0x76,0x77,0x78,0x79])
                arr.push([0x7C,0x7D,0x7E,0x7F,0x80,0x81,0x82])
                arr.push([0x85,0x86,0x87,0x88,0x89,0x8A,0x8B])
                let indexArr = []
                arr[this.tabSelection].forEach(index=>{
                    indexArr.push(this.zone*256 + index)
                })
                return indexArr
            }
        },
        methods:{
            getValue(item){
                let value = this.valueMap[item.index+""] || 0
                let switchValue = this.valueMap[item.switchIndex+""] || 0

                if(item.valueFormatter){
                    return item.valueFormatter(switchValue,value)
                }else if(item.rate){
                    if(item.rate<100){
                        return (value/item.rate).toFixed(1)
                    }else{
                        return (value/item.rate).toFixed(2)
                    }
                }else{
                    return value
                }

            },

            parasWithZone(paras,zone){
                if(!zone){
                    zone = this.zone
                }
                paras.forEach(item=>{
                    if(item.index>0){
                        item.index = zone*256 + item.index
                    }
                    if(item.switchIndex>0){
                        item.switchIndex = zone*256 + item.switchIndex
                    }
                })
                return paras
            },
            timingChange(dataIndex,value){
                ParasUtil.controlFunction({[dataIndex+""]:value})
            }

        }
    }
</script>

<style scoped lang="scss">
.para-card{
    margin-bottom: 20px;
    background: #F8F8F8;
    border-radius: 5px;
}

    .timing-container{
        border-radius: 10px;
        overflow: hidden;
        margin: 15px 5px;
        border: 1px solid #DDD
    }

</style>
